// import { Action, Subject } from '@/types/acl'
import { organizationRepository } from '@/modules/CyberThreatIntelligence/infra/adapters/organization.repository'
import { RouteRecordRaw } from 'vue-router'

export const domainMonitoringFeatureWallRoute: RouteRecordRaw = {
  path: '/domain-monitoring/overview',
  name: 'feature-wall-domain-monitoring',
  component: () => import('./DomainMonitoringFeatureWall.vue'),
  // meta: {
  //   action: Action.READ,
  //   subject: Subject.CYBER_THREAT_INTELLIGENCE,
  // },
  beforeEnter: async (to, from, next) => {
    const { isCyberThreatIntelligenceEnabled } =
      await organizationRepository.isCyberThreatIntelligenceEnabled()

    isCyberThreatIntelligenceEnabled ? next({ name: 'domain-monitoring' }) : next()
  },
}

export const dataBreachesFeatureWallRoute: RouteRecordRaw = {
  path: '/data-breaches/overview',
  name: 'feature-wall-data-breaches',
  component: () => import('./DataBreachesFeatureWall.vue'),
  // meta: {
  //   action: Action.READ,
  //   subject: Subject.CYBER_THREAT_INTELLIGENCE,
  // },
  beforeEnter: async (to, from, next) => {
    const { isCyberThreatIntelligenceEnabled } =
      await organizationRepository.isCyberThreatIntelligenceEnabled()

    isCyberThreatIntelligenceEnabled ? next({ name: 'data-breaches' }) : next()
  },
}
