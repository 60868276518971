import { RouteRecordRaw } from 'vue-router'
import { organizationRepository } from '../infra/adapters/organization.repository'
import { dataBreachesRoute } from '../use-cases/data-breaches/route'
import { domainMonitoringRoute } from '../use-cases/domain-monitoring/route'
import {
  dataBreachesFeatureWallRoute,
  domainMonitoringFeatureWallRoute,
} from './views/featureWall/route'

export const cyberThreatIntelligenceRoutes: RouteRecordRaw[] = [
  {
    name: 'data-breaches',
    path: '/data-breaches',
    redirect: { name: dataBreachesRoute.name },
    beforeEnter: async (to, from, next) => {
      const { isCyberThreatIntelligenceEnabled } =
        await organizationRepository.isCyberThreatIntelligenceEnabled()

      if (isCyberThreatIntelligenceEnabled) {
        if (to.name === dataBreachesFeatureWallRoute.name) {
          next({ name: dataBreachesRoute.name })
        } else {
          next() // Allow navigation
        }
      } else {
        if (to.name !== dataBreachesFeatureWallRoute.name) {
          next({ name: dataBreachesFeatureWallRoute.name })
        } else {
          next() // Already on feature-wall-data-breaches, so allow navigation
        }
      }
    },
    children: [dataBreachesRoute, dataBreachesFeatureWallRoute],
  },
  {
    name: 'domain-monitoring',
    path: '/domain-monitoring',
    redirect: { name: domainMonitoringRoute.name },
    beforeEnter: async (to, from, next) => {
      const { isCyberThreatIntelligenceEnabled } =
        await organizationRepository.isCyberThreatIntelligenceEnabled()

      if (isCyberThreatIntelligenceEnabled) {
        if (to.name === domainMonitoringFeatureWallRoute.name) {
          next({ name: domainMonitoringRoute.name })
        } else {
          next() // Allow navigation
        }
      } else {
        if (to.name !== domainMonitoringFeatureWallRoute.name) {
          next({ name: domainMonitoringFeatureWallRoute.name })
        } else {
          next() // Already on feature-wall-domain-monitoring, so allow navigation
        }
      }
    },
    children: [domainMonitoringRoute, domainMonitoringFeatureWallRoute],
  },
]
